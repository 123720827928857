import { Component, OnInit, AfterViewInit, Input, OnChanges, SimpleChanges, EventEmitter, Output, ChangeDetectorRef, Inject } from '@angular/core';
import * as Highcharts from 'highcharts';
import * as _ from 'lodash';
import { Options } from 'highcharts';
import draggable from "highcharts/modules/draggable-points";
import HC_more from "highcharts/highcharts-more";
import HC_no_data from "highcharts/modules/no-data-to-display";
import HC_drilldown from "highcharts/modules/drilldown";
import HC_accessibility from "highcharts/modules/accessibility";
import HC_map from "highcharts/modules/map";
// import HC_exportData from "highcharts/modules/export-data";
// import HC_theme from 'highcharts/themes/dark-unica';
// import HC_exporting from 'highcharts/modules/exporting';
// HC_theme(Highcharts);
// HC_exporting(Highcharts);
// HC_exportData(Highcharts);
HC_more(Highcharts);
draggable(Highcharts);
HC_no_data(Highcharts);
HC_drilldown(Highcharts);
HC_accessibility(Highcharts);
HC_map(Highcharts);

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss']
})
export class GraphComponent implements OnInit, AfterViewInit, OnChanges {
  Highcharts: typeof Highcharts = Highcharts;
  // @Input() Highcharts: typeof Highcharts;
  @Input() constructorType: string;
  @Input() callbackFunction: Highcharts.ChartCallbackFunction;
  @Input() graphOneToOne: boolean; // #20
  @Input() runOutsideAngular: boolean; // #75
  @Input() options: Highcharts.Options;
  @Input() update: boolean;
  optionsToInput: Highcharts.Options;
  @Output() updateChange = new EventEmitter<boolean>(true);
  @Output() chartInstance = new EventEmitter<Highcharts.Chart>(); // #26
  chart: Highcharts.Chart;
  public query = ['#chartContainer'];
  private docStyle = getComputedStyle(document.documentElement);
  // private preDefinedOptions: Highcharts.Options = {
  //   chart: {
  //     spacingLeft: 0,
  //     backgroundColor: this.docStyle.getPropertyValue('--color-pallet-primary-11'),
  //   },
  //   xAxis: {
  //     labels: {
  //       style: {
  //         color: this.docStyle.getPropertyValue('--color-pallet-primary-02')
  //       },
  //     },
  //     gridLineColor: this.docStyle.getPropertyValue('--color-pallet-primary-06'),
  //     lineColor: this.docStyle.getPropertyValue('--color-pallet-primary-05'),
  //   },
  //   // yAxis: {
  //   //   gridLineColor: this.docStyle.getPropertyValue('--color-pallet-primary-06'),
  //   //   title: {
  //   //     style: {
  //   //       color: this.docStyle.getPropertyValue('--color-pallet-primary-05'),
  //   //     }
  //   //   },
  //   //   labels: {
  //   //     style: {
  //   //       color: this.docStyle.getPropertyValue('--color-pallet-primary-03')
  //   //     }
  //   //   },
  //   //   lineColor: this.docStyle.getPropertyValue('--color-pallet-primary-05'),
  //   // },
  //   legend: {
  //     itemStyle: {
  //       color: this.docStyle.getPropertyValue('--color-pallet-primary-02'),
  //     },
  //     itemHoverStyle: {
  //       color: this.docStyle.getPropertyValue('--color-pallet-primary-01'),
  //     }
  //   },
  // };
  constructor(@Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef) { }
  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options.currentValue) {
      // this.optionsToInput = _.merge(this.preDefinedOptions, changes.options.currentValue);
      this.optionsToInput = changes.options.currentValue;
    }
  }

  setChartInstance(event: Highcharts.Chart) {
    this.chart = event
    this.chartInstance.emit(event);
  }

  updateStatus(event) {
    this.updateChange.emit(event);
  }

  ngAfterViewInit(): void {
    // this.resize();
  }
  public resizeChart(event: ResizeObserverEntry): void {
    // console.log('RESIZE', event);
    if (this.chart) {
      this.chart.setSize(Math.floor((event.contentRect.width < 0) ? 0 : event.contentRect.width), Math.floor(event.contentRect.height));
      this.chart.reflow();
      this.cdr.detectChanges();
    }

  }
  resize() {
    this.chart.setSize(((this.chart.container.getBoundingClientRect().width < 0) ? 0 : this.chart.container.getBoundingClientRect().width));
  }

}
