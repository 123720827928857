import {
    HttpHandler,
    HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { LoadingService } from '../shared/services/loading.service';

@Injectable()
export class HttpCustomInterceptor implements HttpInterceptor {
    constructor(private loadingService: LoadingService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        this.loadingService.loadingThresholdIncrement();
        return next.handle(request).pipe(
            finalize(() => {
                this.loadingService.loadingThresholdDecrement();
            })
        );
    }
}