import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-updatepassword',
  templateUrl: './updatepassword.component.html',
  styleUrls: ['./updatepassword.component.scss']
})

export class UpdatepasswordComponent {

  token: string = "";
  password: string = "";
  confirmPassword: string = "";
  passwordError: boolean = false;
  passwordErrorMessage: string = "";
  passwordSuccess: boolean = false;;
  @ViewChild('resetForm') resetForm!: NgForm;  // Use '!' to tell TypeScript that the object will be initialized properly when in use.

  constructor(private route: ActivatedRoute,private authService: AuthService, private router: Router) {
    this.route.params.subscribe(params => {
      // Access token parameter from the route
      this.token=params['token'];
      console.log("token = ",this.token)
    });
  }

  ngOnInit(): void {
    // Subscribe to route parameter changes
    }

  updatePassword(){
    this.passwordError=false;
    this.passwordErrorMessage = "";
    this.resetForm.controls['newPassword'].markAsTouched();
    this.resetForm.controls['confirmPassword'].markAsTouched();
    console.log("coming here")
    if (!this.resetForm.valid) {
      // this.showErrors();
      return;
    }
    if (this.password !== this.confirmPassword){
      this.passwordError=true;
      this.passwordErrorMessage = "The passwords do not match. Please enter again";
      return;
    }
    this.authService.resetPassword(this.password,this.confirmPassword, this.token)
    .subscribe(
      (response) => {
        console.log('Password reset successful', response);
        this.passwordSuccess = true;
        setTimeout(()=>{
          this.router.navigate(['/market-search']);
        },5000)

      },
      (error) => {
        // Handle error response
        console.error('API failed', error);
        this.passwordError=true;
        this.passwordErrorMessage = error.error.message;
        // this.error = 'Invalid username or password';
      }
    );
  }

  passwordVisible: boolean = false;

  togglePasswordVisibility(): void {
    this.passwordVisible = !this.passwordVisible;
    const newPasswordInput = document.getElementById('newPassword') as HTMLInputElement;
    if (this.passwordVisible) {
      newPasswordInput.type = 'text';
    } else {
      newPasswordInput.type = 'password';
    }
  }

  confirmPasswordVisible: boolean = false;

  toggleConfirmPasswordVisibility(): void {
    this.confirmPasswordVisible = !this.confirmPasswordVisible;
    const confirmPasswordInput = document.getElementById('confirmPassword') as HTMLInputElement;
    if (this.confirmPasswordVisible) {
      confirmPasswordInput.type = 'text';
    } else {
      confirmPasswordInput.type = 'password';
    }
  }
}
