<!-- 1 Figma Artboard Name: User Management -->
<nav class="nav nav-underline">
    <a class="nav-link active" href="javascript:void(0)">User Management</a>
    <a class="nav-link" href="javascript:void(0)">Account Management</a>
</nav>
<div class="sub-action">
    <div class="d-flex align-self-center w-100 min-width">
        <div class="flex-grow-1">
            <select class="custom-select w-auto">
              <option value="" selected>Select Company / Individual</option>
            </select>
        </div>
        <div class="mr-3">
            <button class="btn btn-link btn-sm" disabled>Manage Roles</button>
        </div>
        <div>
            <button class="btn btn-primary" disabled>Add a New User</button>
        </div>
    </div>
</div>
<table class="table table-borderless">
    <thead class="thead-light">
        <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email ID</th>
            <th>Role</th>
            <th class="text-center">Action</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td colspan="5" class="empty-td">
                <div class="icon-settings icon"></div><br>
                Please select the company / Individual account first to see the details
            </td>
        </tr>
    </tbody>
</table>



<!-- 2 Figma Artboard Name: UM - Com Selected -->
<!-- <nav class="nav nav-underline">
    <a class="nav-link active" href="javascript:void(0)">User Management</a>
    <a class="nav-link" href="javascript:void(0)">Account Management</a>
</nav>
<div class="sub-action">
    <div class="d-flex align-self-center w-100">
        <div class="flex-grow-1">
            <select class="custom-select w-auto min-width">
              <option value="" selected>Demo Company Name</option>
            </select>
        </div>
        <div class="mr-3">
            <button class="btn btn-link btn-sm"><span class="icon-table"></span> Export to Excel</button>
        </div>
        <div class="mr-3">
            <button class="btn btn-link btn-sm">Manage Roles</button>
        </div>
        <div>
            <button class="btn btn-primary">Add a New User</button>
        </div>
    </div>
</div>
<table class="table table-borderless table-hover">
    <thead class="thead-light">
        <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email ID</th>
            <th>Role</th>
            <th class="text-center">Action</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Robyn</td>
            <td>Burlingame</td>
            <td>robyn@preferredregistry.com</td>
            <td>Recruiter</td>
            <td class="text-center">
                <button class="btn btn-link btn-sm btn-xsm mr-2">Edit</button> 
                <button class="btn btn-link btn-sm btn-xsm">Delete</button>
            </td>
        </tr>
    </tbody>
</table> -->



<!-- 3 Figma Artboard Name: Account Management -->
<!-- <nav class="nav nav-underline">
    <a class="nav-link" href="javascript:void(0)">User Management</a>
    <a class="nav-link active" href="javascript:void(0)">Account Management</a>
</nav>
<div class="sub-action">
    <div class="d-flex align-self-center w-100">
        <div class="flex-grow-1">
            <div class="position-relative">
                <span class="ic-prepend icon-search"></span>
                <input type="text" class="form-control form-control-sm w-auto min-width searchinput" placeholder="Search Company / Individual">
            </div>
        </div>
        <div class="mr-3">
            <button class="btn btn-link btn-sm"><span class="icon-table"></span> Export to Excel</button>
        </div>
        <div>
            <button class="btn btn-primary">Add a New Account</button>
        </div>
    </div>
</div>
<table class="table table-borderless table-hover">
    <thead class="thead-light">
        <tr>
            <th>Account Name</th>
            <th>Contact Person</th>
            <th>Email ID</th>
            <th>Status</th> 
            <th class="text-center">Action</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Acme Corporation</td>
            <td>Robyn Burlingame</td>
            <td>info@acmecorp.com</td>
            <td>Active</td>
            <td class="text-center">
                <button class="btn btn-link btn-sm btn-xsm mr-2">View</button>
                <button class="btn btn-link btn-sm btn-xsm mr-2">Edit</button>
                <button class="btn btn-link btn-sm btn-xsm mr-2">Inactive</button>
                <button class="btn btn-link btn-sm btn-xsm">Delete</button>
            </td>
        </tr>
    </tbody>
</table> -->



<!-- 4 Figma Artboard Name: AM - New Company -->
<!-- <div class="modal-header">
    <div class="d-flex align-self-center w-100">
        <div class="flex-grow-1">
            <h3>Add a New Account</h3>
        </div>
        <div class="pl-4 align-self-center"><button type="button" class="btn-close" aria-label="Close"></button></div>
    </div>
</div>
<div class="modal-body mb-3">
    <div class="form-group">
        <label for="jobTitle">Account Name <span class="astrix">*</span></label>
        <input type="text" class="form-control">
    </div>
    <div class="form-group">
        <label for="jobTitle">Contact Person <span class="astrix">*</span></label>
        <input type="text" class="form-control">
    </div>
    <div class="form-group">
        <label for="jobTitle">Email ID <span class="astrix">*</span></label>
        <input type="text" class="form-control">
    </div>
    <div class="form-group">
        <label>Services opted</label>
        <div class="custom-control custom-checkbox mb-2">
            <input type="checkbox" class="custom-control-input" id="customControlValidation1" name="checkbox">
            <label class="custom-control-label" for="customControlValidation1">Market Search and Analytics</label>
        </div>
        <div class="custom-control custom-checkbox mb-2">
            <input type="checkbox" class="custom-control-input" id="customControlValidation2" name="checkbox">
            <label class="custom-control-label" for="customControlValidation2">Recruitment Module</label>
        </div>
        <div class="custom-control custom-checkbox mb-2">
            <input type="checkbox" class="custom-control-input" id="customControlValidation3" name="checkbox">
            <label class="custom-control-label" for="customControlValidation3">Forecasting</label>
        </div>
        <div class="custom-control custom-checkbox mb-2">
            <input type="checkbox" class="custom-control-input" id="customControlValidation4" name="checkbox">
            <label class="custom-control-label" for="customControlValidation4">User Management</label>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div>
        <button class="btn btn-outline-primary mr-3">Cancel</button>
        <button class="btn btn-primary">Submit</button>
    </div>
</div> -->



<!-- 5/6 Figma Artboard Name: UM - New User and UM - Role Details-->
<!-- <div class="modal-header">
    <div class="d-flex align-self-center w-100">
        <div class="flex-grow-1">
            <h3>Add a New User</h3>
        </div>
        <div class="pl-4 align-self-center"><button type="button" class="btn-close" aria-label="Close"></button></div>
    </div>
</div>
<div class="modal-body mb-3">
    <div class="row">
        <div class="col-sm-4">
            <div class="form-group">
                <label for="jobTitle">First Name <span class="astrix">*</span></label>
                <input type="text" class="form-control">
            </div>
            <div class="form-group">
                <label for="jobTitle">Last Name <span class="astrix">*</span></label>
                <input type="text" class="form-control">
            </div>
            <div class="form-group">
                <label for="jobTitle">Email ID <span class="astrix">*</span></label>
                <input type="text" class="form-control">
            </div>
            <div class="form-group">
                <label for="ListingDate">Select Role <span class="astrix">*</span> <span class="icon-info ml-2"></span></label>
                <select class="custom-select form-control" id="ListingDate">
                    <option selected>Choose...</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
            </div>
        </div>
        <div class="col-sm-8">
            <div class="role-detail-wrapper">
                <nav class="nav nav-underline">
                    <a class="nav-link active" href="javascript:void(0)">Admin</a>
                    <a class="nav-link" href="javascript:void(0)">Sales</a>
                    <a class="nav-link" href="javascript:void(0)">Hiring Manager</a>
                    <a class="nav-link" href="javascript:void(0)">Recruiter</a>
                </nav>
                <div class="role-description">
                    <div class="rd-list">
                        Market Search and Analytics
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In egestas lorem non nulla sagittis</p>
                    </div>
                    <div class="rd-list">
                        Recruitment Module
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In egestas lorem non nulla sagittis</p>
                    </div>
                    <div class="rd-list">
                        Forecasting
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In egestas lorem non nulla sagittis</p>
                    </div>
                    <div class="rd-list">
                        Users Management
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In egestas lorem non nulla sagittis</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div>
        <button class="btn btn-outline-primary mr-3">Cancel</button>
        <button class="btn btn-primary">Submit</button>
    </div>
</div> -->



<!-- 7 Figma Artboard Name: UM - Manage Role -->
<!-- <div class="modal-header">
    <div class="d-flex align-self-center w-100">
        <div class="flex-grow-1">
            <h3>Manage Role(s)</h3>
        </div>
        <div class="pl-4 align-self-center"><button type="button" class="btn-close" aria-label="Close"></button></div>
    </div>
</div>
<div class="modal-body mb-3">
    <table class="table table-borderless table-hover">
        <thead class="thead-light">
            <tr>
                <th>Role Name</th>
                <th>Role Summary</th>
                <th class="text-center">Action</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Recruiter</td>
                <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit</td>
                <td class="text-center">
                    <button class="btn btn-link btn-sm btn-xsm mr-2">Edit</button> 
                    <button class="btn btn-link btn-sm btn-xsm">Delete</button>
                </td>
            </tr>
            <tr>
                <td>Hiring Manager</td>
                <td>Nam mattis, metus nec finibus auctor.</td>
                <td class="text-center">
                    <button class="btn btn-link btn-sm btn-xsm mr-2">Edit</button> 
                    <button class="btn btn-link btn-sm btn-xsm">Delete</button>
                </td>
            </tr>
            <tr>
                <td>Admin</td>
                <td>Phasellus sed dui ac nibh vehicula pharetra. Maecenas.</td>
                <td class="text-center">
                    <button class="btn btn-link btn-sm btn-xsm mr-2">Edit</button> 
                    <button class="btn btn-link btn-sm btn-xsm">Delete</button>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div class="modal-footer">
    <div>
        <button class="btn btn-outline-primary mr-3">Cancel</button>
        <button class="btn btn-primary">Submit</button>
    </div>
</div> -->



<!-- 8 Figma Artboard Name: UM - New Role -->
<!-- <div class="modal-header">
    <div class="d-flex align-self-center w-100">
        <div class="flex-grow-1">
            <h3>Add a New Role</h3>
        </div>
        <div class="pl-4 align-self-center"><button type="button" class="btn-close" aria-label="Close"></button></div>
    </div>
</div>
<div class="modal-body mb-3">
    <div class="form-group">
        <label for="jobTitle">Role Name <span class="astrix">*</span></label>
        <input type="text" class="form-control">
    </div>
    <div class="form-group">
        <label for="jobTitle">Role Summary </label>
        <input type="text" class="form-control">
    </div>
    <div class="form-group">
        <label>Choose Privileges </label>
        <div class="custom-control custom-checkbox mb-2">
            <input type="checkbox" class="custom-control-input" id="customControlValidation1" name="checkbox">
            <label class="custom-control-label" for="customControlValidation1">Market Search and Analytics</label>
        </div>
        <div class="custom-control custom-checkbox mb-2">
            <input type="checkbox" class="custom-control-input" id="customControlValidation2" name="checkbox">
            <label class="custom-control-label" for="customControlValidation2">Recruitment Module</label>
        </div>
        <div class="custom-control custom-checkbox mb-2">
            <input type="checkbox" class="custom-control-input" id="customControlValidation3" name="checkbox">
            <label class="custom-control-label" for="customControlValidation3">Forecasting</label>
        </div>
        <div class="custom-control custom-checkbox mb-2">
            <input type="checkbox" class="custom-control-input" id="customControlValidation4" name="checkbox">
            <label class="custom-control-label" for="customControlValidation4">User Management</label>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div>
        <button class="btn btn-outline-primary mr-3">Cancel</button>
        <button class="btn btn-primary">Submit</button>
    </div>
</div> -->