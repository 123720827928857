import { inject } from '@angular/core';
import { CanActivateFn, CanMatchFn, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

export const authRoutesAppearanceGuard: CanMatchFn = (route, state) => {
  const authSer = inject(AuthService);
  const router = inject(Router);
  if (authSer.isLoggedIn) {
    router.navigate(['/']);
    return false;
  } else {
    return true;
  }
};
