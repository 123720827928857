<nav class="nav nav-underline">
  <a [routerLink]="['/management/users']" class="nav-link" href="javascript:void(0)">User Management</a>
  <a *ngIf="user?.role == 'accountmanager'" class="nav-link active" href="javascript:void(0)">Account Management</a>
</nav>
<div class="sub-action">
  <div class="d-flex align-self-center w-100">
    <div class="flex-grow-1">
    <!--
        <div class="position-relative">
          <input type="text" class="form-control form-control" placeholder="Search">
        </div>
      -->
    </div>
    <!--
    <div class="mr-3">
      <button class="btn btn-link btn-sm"><span class="icon-table"></span> Export to Excel</button>
    </div>
    -->
    <div>
      <button class="btn btn-primary" (click)="openAddAccountModal()">Add New Account</button>
    </div>
  </div>
</div>
<table class="table table-borderless table-hover">
  <thead class="thead-light">
    <tr>
      <th>Account Name</th>
      <th>Contact Person</th>
      <th>Email ID</th>
      <th>Status</th>
      <th class="text-center">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let account of accounts">
      <td>{{account.name}} <sup>{{account.userLicenses}}</sup></td>
      <td>{{account.contactPerson}}</td>
      <td>{{account.email}}</td>
      <td>{{account.isEnabled?'ACTIVE':'INACTIVE'}}</td>
      <td class="text-center">
        <button class="btn btn-link btn-sm btn-xsm mr-2" (click)="openEditAccountModal(account._id)">Edit</button>
        <div class="form-check form-switch d-inline">
          <input class="form-check-input mt-1" type="checkbox" [ngModel]="account.isEnabled" (change)="changeAccountStatus(account._id, account.isEnabled)" role="switch">
        </div>
      </td>
    </tr>
  </tbody>
</table>
