import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { error } from 'console';
import { MessageService } from 'primeng/api';
import { AccountManagementService } from 'src/app/shared/services/account-management.service';
import { Account } from 'src/app/shared/types/account';
import { ApiResponse } from 'src/app/shared/types/api-response';

const phoneEmailRegex = /^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/;
@Component({
  selector: 'app-add-edit-account',
  templateUrl: './add-edit-account.component.html',
  styleUrls: ['./add-edit-account.component.scss']
})
export class AddEditAccountComponent implements OnInit {
  private id: string;
  public account: Account;
  public nameError: boolean = false;
  public emailError: boolean = false;
  public contactError: boolean = false;
  public userLicensesError: boolean = false;
  public title: string;

  constructor(fb: FormBuilder, public activeModal: NgbActiveModal, private accountService: AccountManagementService, private changeDetection: ChangeDetectorRef, private messageService: MessageService) {
  }

  ngOnInit(): void {
    if (!this.id) {
      this.title = "Add New Account";
      this.account = new Account({hasForecasting: true, hasMarketAnalysis: true});
      this.account.userLicenses = 1;
    }
    else {
      this.title = "Edit Account";
      this.accountService.getAccountById(this.id).subscribe(data => {
        this.account = new Account((data as ApiResponse).data);
        //this.changeDetection.detectChanges()
      });
    }
  }

  onSubmit() {
    if(!this.account.name) this.nameError = true; else this.nameError = false;
    if (!this.account.email || !this.isEmail(this.account.email)) this.emailError = true; else this.emailError = false;
    if(!this.account.contactPerson) this.contactError = true; else this.contactError = false;
    if(!this.account.userLicenses || this.account.userLicenses < 1) this.userLicensesError = true; else this.userLicensesError = false;

    if(this.nameError || this.emailError || this.contactError || this.userLicensesError) return;

    if (this.id) this.updateAndClose();
    else this.saveAndClose();
  }

  saveAndClose() {
    this.accountService.addAccount(this.account).subscribe(data => {
      this.activeModal.close('success');
    }, error => {
      this.messageService.add({severity:'error', summary:'Error in adding account', detail: error?.error?.message});
    });
  }

  updateAndClose() {
    this.accountService.updateAccount(this.id, this.account).subscribe(data => {
      this.activeModal.close('success');
    }, error => {
      this.messageService.add({severity:'error', summary:'Error in updating account', detail: error?.error?.message});
    });
  }

  close() {
    this.activeModal.dismiss();
  }

  isEmail(val: string) {
    return String(val)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }
}
