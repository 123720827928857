import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { LoadingService } from './shared/services/loading.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'talentedge';
  data: any;
  loading = false;

  constructor(private http: HttpClient,
     public loadingSer:LoadingService,
     private cd: ChangeDetectorRef
    ) {}

  ngOnInit(): void {
    this.loadingSer.isLoading$.subscribe(load => {
      this.loading = load;
      this.cd.detectChanges();
    })
  }
}
