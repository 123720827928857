<nav class="nav nav-underline">
  <a class="nav-link active" href="javascript:void(0)">User Management</a>
  <a *ngIf="user?.role == 'accountmanager'" [routerLink]="['/management/accounts']" class="nav-link" href="javascript:void(0)">Account Management</a>
</nav>
<div class="sub-action">
  <div class="d-flex align-self-center w-100 min-width">
      <div class="flex-grow-1">
          <select class="custom-select w-auto" [(ngModel)]="selectedAccountId" (change)="onChangeAccount()">
            <option value="0" selected>Select Company / Account</option>
            <option *ngFor="let account of accounts" value="{{account._id}}">{{account.name}}</option>
          </select>
      </div>
      <div>
          <button class="btn btn-primary" [disabled]="(selectedAccountId == '0') || (user?.role != 'accountmanager')" (click)="openAddUserModal()">Add New User</button>
      </div>
  </div>
</div>
<table class="table table-borderless">
  <thead class="thead-light">
      <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Email ID</th>
          <th>Role</th>
          <th class="text-center">Action</th>
      </tr>
  </thead>
  <tbody>
      <tr *ngIf="users?.length == 0">
          <td colspan="5" class="empty-td">
              <div class="icon-settings icon"></div><br>
              Please select the company / Individual account first to see the details
          </td>
      </tr>
      <tr *ngFor="let user of users">
        <td>{{user.firstName}}</td>
        <td>{{user.lastName}}</td>
        <td>{{user.email}}</td>
        <td>{{user.role.toUpperCase()}}</td>
        <td class="text-center">
          <button class="btn btn-link btn-sm btn-xsm mr-2" (click)="openEditUserModal(user._id)">Edit</button>
          <div class="form-check form-switch d-inline">
            <input class="form-check-input mt-1" type="checkbox" [ngModel]="user.isEnabled" (change)="changeUserStatus(user._id, user.isEnabled)" role="switch">
          </div>
        </td>
      </tr>
</tbody>
</table>

