import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'primeng/api';
import { AccountManagementService } from 'src/app/shared/services/account-management.service';
import { ApiResponse } from 'src/app/shared/types/api-response';
import { User } from 'src/app/shared/types/user';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-edit-user',
  templateUrl: './add-edit-user.component.html',
  styleUrls: ['./add-edit-user.component.scss']
})
export class AddEditUserComponent implements OnInit {
  id: string;
  accountId: string;
  public user: User;
  public environment = environment;
  message: string;
  firstNameError: boolean = false;
  emailError: boolean = false;
  roleError: boolean = false;

  constructor(public activeModal: NgbActiveModal, private accountService: AccountManagementService, private changeDetection: ChangeDetectorRef, private messageService: MessageService) { }

  ngOnInit(): void {
    if (!this.id) this.user = new User;
    else {
      this.accountService.getUserById(this.id).subscribe(data => {
        this.user = new User((data as ApiResponse).data);
        this.changeDetection.detectChanges()
      });
    }
  }

  onSubmit() {
    if(!this.user.firstName) this.firstNameError = true; else this.firstNameError = false;
    if (!this.user.email || !this.isEmail(this.user.email)) this.emailError = true; else this.emailError = false;
    if(!this.user.role) this.roleError = true; else this.roleError = false;

    if(this.firstNameError || this.emailError || this.roleError) return;

    if (this.id) this.updateAndClose();
    else this.saveAndClose();
  }

  saveAndClose() {
    this.accountService.addUser(this.accountId, this.user).subscribe(data => {
      this.activeModal.close('success');
    }, error => {
      this.messageService.add({severity:'error', summary:'Error in adding user', detail: error?.error?.message});
    });
  }

  updateAndClose() {
    this.accountService.updateUser(this.id, this.user).subscribe(data => {
      this.activeModal.close('success');
    }, error => {
      this.messageService.add({severity:'error', summary:'Error in updating user', detail: error?.error?.message});
    });
  }

  close() {
    this.activeModal.dismiss();
  }

  resetPassword() {
    this.accountService.resetUserPassword(this.id).subscribe(data => {
      alert((data as ApiResponse).message);
      this.activeModal.close('success');
    }, error => {
      this.messageService.add({severity:'error', summary:'Error in resetting password', detail: error?.error?.message});
    });
  }

  isEmail(val: string) {
    return String(val)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }

}
