import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountManagementService } from 'src/app/shared/services/account-management.service';
import { AddEditUserComponent } from '../add-edit-user/add-edit-user.component';
import { ApiResponse } from 'src/app/shared/types/api-response';
import { User } from 'src/app/shared/types/user';
import { AuthService } from 'src/app/auth/auth.service';
import { error } from 'console';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, AfterViewInit {
  accounts: any = [];
  users: Array<User> = [];
  selectedAccountId: string = "0";
  user: User | null;

  constructor(private modalService: NgbModal, private accountsManagement: AccountManagementService, private authService: AuthService, private changeDetection: ChangeDetectorRef, private messageService: MessageService) {

  }

  ngOnInit(): void {
    this.user = this.authService.currentUser;
  }

  ngAfterViewInit(): void {
    this.accountsManagement.getAllAccounts().subscribe(data => {
      console.log(JSON.stringify(data));
      this.accounts = (data as ApiResponse).data;
      this.changeDetection.detectChanges();
    })
  }

  onChangeAccount() {
    this.users = [];
    this.changeDetection.detectChanges();
    if(this.selectedAccountId == "0") return;
    this.accountsManagement.getAllUsers(this.selectedAccountId).subscribe(success => {
      console.log(JSON.stringify(success));
      const users = (success as ApiResponse).data as Array<User>;
      this.users = users.map(u => new User(u));
      this.changeDetection.detectChanges();
    }, error => {

    });
  }

  openAddUserModal() {
    const modalRef = this.modalService.open(AddEditUserComponent,
      {
        size: 'md',
        centered: true,
        scrollable: true,
        keyboard: true
      });
    modalRef.componentInstance.accountId = this.selectedAccountId;

    modalRef.result.then(() => {
      this.onChangeAccount();
    });
  }

  openEditUserModal(id: string) {
    const modalRef = this.modalService.open(AddEditUserComponent,
      {
        size: 'md',
        centered: true,
        scrollable: true,
        keyboard: true
      });
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.accountId = this.selectedAccountId;

    modalRef.result.then(() => {
      this.onChangeAccount();
    });
  }

  changeUserStatus(id, enable) {
    if (enable) {
      this.accountsManagement.disableUser(id).subscribe(data => {
        console.log(data)
      }, error => {
        this.messageService.add({severity:'error', summary:'Error updating user', detail: error?.error?.message}); }
      ); }
    else {
      this.accountsManagement.enableUser(id).subscribe(data => {
        console.log(data)
      }, error => {
        this.messageService.add({severity:'error', summary:'Error updating user', detail: error?.error?.message}); }
      ); }
      this.onChangeAccount();
  }
}
