import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
    selector: 'app-updatepass',
    templateUrl: './updatepass.component.html',
    styleUrls: ['./updatepass.component.scss']
})
export class UpdatepassComponent {
    email: string = '';
    token: string = ''; // Add a token property to store the token value
    passwordError: boolean = false;
    passwordErrorMessage: any;
    passwordSuccess: boolean = false;

    constructor(private authService: AuthService, private router: Router) { }

    // Method to send an email for password reset
    sendEmail() {
        this.passwordError = false;
        // Ensure both email and token are provided if your forgetPassword method accepts both
        if (this.email) {
            // Call the forgetPassword method with just the email if it accepts only one argument
            this.authService.forgetPassword(this.email)
                .subscribe(
                    response => {
                        console.log('Email sent successfully', response);
                        // Redirect user to the login page after successful email sending
                        this.passwordSuccess = true;
                        // setTimeout(()=>{
                        //     this.router.navigate(['/auth/login']);
                        // },5000);
                        localStorage.setItem("forgetEmail",this.email);
                        this.router.navigate(['auth/forget-password']);
                    },
                    error => {
                        // Handle error response
                        console.error('Failed to send password reset email', error);
                        // Optionally, display an error message to the user
                        this.passwordError = true; // Set the error state
                        this.passwordErrorMessage = error.error.message;
                    }
                );
        } else {
            // Handle the case where the email is missing
            console.error('Email not provided');
            // Optionally, display an error message to the user
        }
    }
}
