import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
;
import { NotFoundComponent } from './not-found/not-found.component'
import { NgModule } from '@angular/core';
// import { BrowserAnimationsModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardLayoutComponent } from './layout/dashboard-layout/dashboard-layout.component';
import { BlankLayoutComponent } from './layout/blank-layout/blank-layout.component';
import { SharedModule } from './shared/shared.module';
import { DashboardV2Component } from './dashboard-v2/components/dashboard/dashboard-v2.component';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { HttpCustomInterceptor } from './core/http_custom_interceptor';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AuthModule } from './auth/auth.module';
import { JwtInterceptorInterceptor } from './core/jwt-interceptor.interceptor';
import { AccountManagementModule } from './account-management/account-management.module';
import { UserManagementComponent } from './user-management/components/user/user-management.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardLayoutComponent,
    BlankLayoutComponent,
    NotFoundComponent,
    UserManagementComponent,
    // DashboardV2Component,
  ],
  imports: [
    // BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    ToastModule,
    ProgressSpinnerModule,
    AccountManagementModule,
    AuthModule
  ],
  providers: [
    MessageService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpCustomInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
