<div class="login-wrapper">
  <header class="header">
    <img src="assets/logo/Login-Logo.svg" alt="TalentEdgeAI" class="logo" />
  </header>
  <div class="container">
    <div class="row">
      <div class="col-md-8 text-center">
        <img src="assets/Login-Graphic.svg" alt="login-page-Image" class="login-graphic">
      </div>
      <div class="col-md-4">
        <div class="login-container">
          <h3>Forgot Password</h3>
          <form (ngSubmit)="sendEmail()" #emailForm="ngForm">
            <div class="rs-mb-5 position-relative">
              <input type="email" [(ngModel)]="email" class="form-control form-control-lg" id="email" name="email"
                placeholder="Email ID *" required email #emailInput="ngModel">
              <!-- Display error message if the email input is invalid -->
              <div *ngIf="emailInput.invalid && emailInput.touched" class="rs-error">
                Please enter a valid email address.
              </div>
            </div>
            <div *ngIf="passwordError" class="alert alert-danger" role="alert">
              The email you have provided is incorrect. Please try again, or
              <a target="_blank" href="mailto:fawadhussain@techgenies.com">Contact</a> the system administrator.
            </div>
            <div *ngIf="passwordSuccess" class="alert alert-success" role="alert">
              Reset Password Email Sent
            </div>
            <button type="submit" class="btn btn-primary w-100 btn-lg rs-mb-5" [disabled]="emailForm.invalid">
              Reset Password
            </button>
          </form>
          <button type="button" routerLink="/auth/login" class="btn btn-link btn-sm w-100">Back to Login</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="footer">© 2024 TalentEdgeAI All rights reserved. | <a href="#">Policy</a> | <a href="#">Terms of Service</a></div>





