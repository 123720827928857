<div class="">
  <div class="modal-header">
    <div class="d-flex align-self-center w-100">
      <div class="flex-grow-1">
        <h3>{{title}}</h3>
      </div>
      <div class="pl-4 align-self-center"><button type="button" class="btn-close" aria-label="Close"
          (click)="close()"></button></div>
    </div>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label for="jobTitle">Account Name <span class="astrix">*</span></label>
      <input type="text" class="form-control" [ngClass]="{'is-invalid' : nameError}" [(ngModel)]="account.name">
    </div>
    <div class="form-group">
      <label for="jobTitle">Contact Person <span class="astrix">*</span></label>
      <input type="text" class="form-control" [ngClass]="{'is-invalid' : contactError}"
        [(ngModel)]="account.contactPerson">
    </div>
    <div class="form-group">
      <label for="email">Email ID <span class="astrix">*</span></label>
      <input type="email" id="email" name="email" class="form-control" [ngClass]="{'is-invalid' : emailError}"
        [(ngModel)]="account.email">
    </div>
    <div class="form-group">
      <label for="userLicenses">User License Entitlement <span class="astrix">*</span></label>
      <input type="userLicenses" id="userLicenses" name="userLicenses" class="form-control" [ngClass]="{'is-invalid' : userLicensesError}"
        [(ngModel)]="account.userLicenses">
    </div>
    <div class="form-group">
      <label>Licenses</label>
      <div class="custom-control custom-checkbox mb-2">
        <input type="checkbox" class="custom-control-input" id="hasMarketAnalysis" name="checkbox"
          [(ngModel)]="account.hasMarketAnalysis">
        <label class="custom-control-label" for="hasMarketAnalysis">Market Search and Analytics</label>
      </div>
      <div class="custom-control custom-checkbox mb-2">
        <input type="checkbox" class="custom-control-input" id="hasRecruitment" name="checkbox"
          [(ngModel)]="account.hasRecruitment">
        <label class="custom-control-label" for="hasRecruitment">Requisitions and Intervu.ai Module</label>
      </div>
      <div class="custom-control custom-checkbox mb-2">
        <input type="checkbox" class="custom-control-input" id="hasForecasting" name="checkbox"
          [(ngModel)]="account.hasForecasting">
        <label class="custom-control-label" for="hasForecasting">Forecasting</label>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div>
      <button class="btn btn-outline-primary mr-3" (click)="close()">Cancel</button>
      <button class="btn btn-primary" (click)="onSubmit()">Submit</button>
    </div>
  </div>
</div>
