import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private isLoading$$ = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoading$$.asObservable();
  private loadingThreshold = 0;

  private setLoading(isLoading: boolean) {
    this.isLoading$$.next(isLoading);
  }

  loadingThresholdIncrement() {
    this.loadingThreshold++;
    if(!this.isLoading$$.value){
      this.setLoading(true);
    }
  }

  loadingThresholdDecrement() {
    if (this.loadingThreshold > 0) this.loadingThreshold--;
    if (this.loadingThreshold < 1) {
      this.loadingThreshold = 0;
      this.setLoading(false);
    }
  }
}

