<div class="login-wrapper">
    <header class="header">
      <img src="assets/logo/Login-Logo.svg" alt="TalentEdgeAI" class="logo" />
    </header>
    <div class="container">
      <div class="row">
        <div class="col-md-8 text-center">
          <img src="assets/Login-Graphic.svg" alt="login-page-Image" class="login-graphic">
        </div>
        <div class="col-md-4">
          <div class="login-container">
            <h3>Forgot Password</h3>
            

            <div class="forgot-key pb-4 text-center"><img src="assets/images/forgot-key.svg"></div>
            <div class="forgot-text pb-4">
                We have sent a link to your email id <a href="mailto:{{email}}"><b>{{email}}</b></a> to reset your password. 
            </div>
            <div class="forgot-help">
                Need some help?
                <div class="dropdown-divider"></div>
                <div class="d-flex align-items-center">
                    <div class="pr-2"><span class="icon-envelope envolop"></span></div> 
                    <div>
                        Email us
                        <span>Contact us via email</span>
                    </div>
                </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">© 2024 TalentEdgeAI All rights reserved. | <a href="#">Policy</a> | <a href="#">Terms of Service</a></div>