import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent {
  public email:any= "";
  constructor(private router: Router) {
  }

  ngOnInit(): void {
    this.email = localStorage.getItem("forgetEmail");

    // Subscribe to route parameter changes
    setTimeout(()=>{
      this.router.navigate(['auth/login']);
    },10000);

    }
}
