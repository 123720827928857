import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlankLayoutComponent } from './layout/blank-layout/blank-layout.component';
import { DashboardLayoutComponent } from './layout/dashboard-layout/dashboard-layout.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { authGuard } from './core/auth.guard';
import { authRoutesAppearanceGuard } from './core/auth-routes-appearance.guard';

const routes: Routes = [
    { path: '', redirectTo:'/d/market-search', pathMatch:'full' },

    {
        canMatch: [authRoutesAppearanceGuard],
        path: 'auth',
        component: BlankLayoutComponent,
        children: [{
            path: '', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
        }]
    },
    {
        path: 'd',
        component: DashboardLayoutComponent,
        canMatch: [authGuard],
        children: [{
            path: '', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
        }]
    },
    {
        path: 'market-search',
        // component: DashboardLayoutComponent,
        canMatch: [authGuard],
        children: [{
            path: '', loadChildren: () => import('./dashboard-v2/dashboard-v2.module').then(m => m.DashboardModule)
        }]
    },
    {
      path: 'management',
      component: DashboardLayoutComponent,
      canMatch: [authGuard],
      children: [{
          path: '', loadChildren: () => import('./account-management/account-management.module').then(m => m.AccountManagementModule)
      }]
    },
  {
        path: '404',
        component: NotFoundComponent
    },
    {
        path: 'user-management',
        // component: DashboardLayoutComponent,
        children: [{
            path: '', loadChildren: () => import('./user-management/user-management.module').then(m=>m.UserManagementModule)
        }]
    },
    { path: '**', redirectTo: '/404' }

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
