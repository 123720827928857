import { Account } from "./account";

export class User {
  public constructor(init?: Partial<User>) {
    Object.assign(this, init);
  }
  public email: string;
  public role: string;
  public _id: string;
  public firstName: string;
  public lastName: string;
  public accountId: string;
  public accountName: string;
  public hasRecruitment: boolean;
  public hasForecasting: boolean;
  public hasMarketAnalysis: boolean;
  public isEnabled: boolean;
}
