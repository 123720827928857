import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private recentActivitiesSource = new BehaviorSubject<any[]>([]);
  recentActivities$ = this.recentActivitiesSource.asObservable();
  private stateNames = { 'VI': 'Virgin Islands', 'PR': 'Puerto Rico', 'PW': 'Palau', 'MP': 'Northern Mariana Islands', 'MH': 'Marshall Islands', 'GU': 'Guam', 'FM': 'Federated States of Micronesia', 'DC': 'District of Columbia', 'AS': 'American Samoa', 'AL': 'Alabama', 'AK': 'Alaska', 'AZ': 'Arizona', 'AR': 'Arkansas', 'CA': 'California', 'CO': 'Colorado', 'CT': 'Connecticut', 'DE': 'Delaware', 'FL': 'Florida', 'GA': 'Georgia', 'HI': 'Hawaii', 'ID': 'Idaho', 'IL': 'Illinois', 'IN': 'Indiana', 'IA': 'Iowa', 'KS': 'Kansas', 'KY': 'Kentucky', 'LA': 'Louisiana', 'ME': 'Maine', 'MD': 'Maryland', 'MA': 'Massachusetts', 'MI': 'Michigan', 'MN': 'Minnesota', 'MS': 'Mississippi', 'MO': 'Missouri', 'MT': 'Montana', 'NE': 'Nebraska', 'NV': 'Nevada', 'NH': 'New Hampshire', 'NJ': 'New Jersey', 'NM': 'New Mexico', 'NY': 'New York', 'NC': 'North Carolina', 'ND': 'North Dakota', 'OH': 'Ohio', 'OK': 'Oklahoma', 'OR': 'Oregon', 'PA': 'Pennsylvania', 'RI': 'Rhode Island', 'SC': 'South Carolina', 'SD': 'South Dakota', 'TN': 'Tennessee', 'TX': 'Texas', 'UT': 'Utah', 'VT': 'Vermont', 'VA': 'Virginia', 'WA': 'Washington', 'WV': 'West Virginia', 'WI': 'Wisconsin', 'WY': 'Wyoming' };

  constructor() { }

  updateRecentActivities(activities: any[]) {
    this.recentActivitiesSource.next(activities);
  }
  // Mapping of US state abbreviations to full names
  // Function to return full state name from abbreviation
  getStateFullName(abbreviation) {
    const upperAbbreviation = abbreviation?.toUpperCase();
    if (this.stateNames.hasOwnProperty(upperAbbreviation)) {
      return this.stateNames[upperAbbreviation];
    }
    else {
      return abbreviation;
    }
  }
}
