import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { UpdatepassComponent } from './updatepass/updatepass.component';
import { UpdatepasswordComponent } from './updatepassword/updatepassword.component';
import { SharedModule } from '../shared/shared.module';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { AuthService } from './auth.service';


@NgModule({
  declarations: [LoginComponent, UpdatepassComponent, UpdatepasswordComponent, ForgetPasswordComponent],
  imports: [
    CommonModule,
    AuthRoutingModule,
    SharedModule
  ],
  providers: [AuthService]
})
export class AuthModule { }
