<div class="sidebar">
  <div class="navbar">
    <!-- <a href="javascript:void(0);" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
      routerLink="/d/market-search" class="navbar-items"><span class="icon-nav-dashboard"></span> Market Search</a> -->
    <a href="javascript:void(0);" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" routerLink="/d"
      class="navbar-items"><span class="icon-nav-dashboard"></span> Dashboard</a>
    <a href="javascript:void(0);" class="navbar-items"><span class="icon-nav-candidates"></span> Candidates</a>
    <a *ngIf="(user?.role != 'sales') && (user?.hasRecruitment == true)" href="javascript:void(0);" class="navbar-items"
      (click)="openRequisitionsPage()"><span style="margin-right: 9px;"
        class="icon-nav-requisition"></span>Requisitions</a>
    <a *ngIf="(user?.role != 'sales') && (user?.hasRecruitment == true)" href="javascript:void(0);" class="navbar-items"
      (click)="openInterviewsPage()"><span class="icon-nav-interview"></span> Interview</a>
    <a href="javascript:void(0);" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"
      [routerLink]="['/d/forecasting']"
      [queryParams]="{query: this.lastActivity?.result,allResData:this.lastActivity.allResData, search:this.lastActivity?.query,soc6Code:this.lastActivity?.soc6Code,jobCategory:this.lastActivity?.jobCategory,state:this.lastActivity?.dataMemberValue}"
      [queryParamsHandling]="'merge'" class="navbar-items"><span class="icon-nav-forecast"></span> Forecasting &
      Analytics</a>
    <a *ngIf="user?.role == 'accountmanager' || user?.role == 'admin'" href="javascript:void(0);"
      routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}" [routerLink]="['/management']"
      class="navbar-items"><span class="icon-nav-usermanagement"></span> User
      Management</a>
  </div>
  <div class="recent-activities">
    <h3>Recent Activities</h3>
    <div *ngIf="recentActivityList.length > 0">
      <div *ngFor="let item of recentActivityList">
        <div class="rec-timestamp">{{item.date}}</div>
        <div class="rec-items" *ngFor="let query of item.queries"> <span
            (click)="onActivityClick(query)">{{query.query}}</span>
          <div class="del-item icon-delete" (click)="archiveActivity(query.activityId)"></div>
        </div>
      </div>
    </div>
    <div class="rec-empty" *ngIf="recentActivityList.length ==0">
      <div class="emptyicon icon-settings"></div>
      No recent Activities
    </div>
    <!-- <div class="rec-items">I am looking a markets for hiring low cost cloud developer <div class="del-item icon-delete"></div></div>
    <div class="rec-timestamp">April, 2024</div>
    <div class="rec-items">What are the top 5 low cost markets for UX Designers <div class="del-item icon-delete"></div></div>
    <div class="rec-timestamp">March, 2024</div>
    <div class="rec-items">Looking for best markets to hire remote sales people <div class="del-item icon-delete"></div></div>
    <div class="rec-timestamp">February, 2024</div>
    <div class="rec-items">Looking for full stack developers in Dallas with 5 years experience in high tech <div class="del-item icon-delete"></div></div>
    <div class="rec-timestamp">February, 2024</div>
    <div class="rec-items">Looking for full stack developers in Dallas with 5 years experience in high tech <div class="del-item icon-delete"></div></div> -->
  </div>
</div>