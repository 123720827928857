import { inject } from '@angular/core';
import { CanMatchFn, Router, } from '@angular/router';
import { AuthService } from '../auth/auth.service';

export const authGuard: CanMatchFn = (route, state) => {
  const authSer = inject(AuthService);
  if (authSer.isLoggedIn) return true;
  const router: Router = inject(Router);
  const nav = router.getCurrentNavigation();
  const redirectUrl: string = nav? nav.extractedUrl.toString() : '';
  authSer.logout(encodeURIComponent(redirectUrl));
  return false;
};
