<div class="login-wrapper">
    <header class="header">
      <img src="assets/logo/Login-Logo.svg" alt="TalentEdgeAI" class="logo" />
    </header>
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center oops-container">
          <img src="assets/oops.svg" alt="Oops" class="oopsgraphic">
          <h3>Sorry, the page not found!</h3>
          <p>The link you followed probably broken or the page has been removed.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">© 2024 TalentEdgeAI All rights reserved. | <a href="#">Policy</a> | <a href="#">Terms of Service</a></div>