import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class JwtInterceptorInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(this.authService.isLoggedIn) {
      const apiRoutes: Array<string> = [environment.aiModelUrl, environment.apiUrl, environment.authUrl, environment.forecastingService, environment.interviewApiUrl, environment.queryEngineUrl];
      const isApiCall = apiRoutes.some((route) => {
        return true;
      });
      if(isApiCall) {
        request = request.clone({
          setHeaders: { Authorization: `Bearer ${this.authService.token}` }
      });
      }
    }
    return next.handle(request);
  }
}
