import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddEditAccountComponent } from '../add-edit-account/add-edit-account.component';
import { AccountManagementService } from 'src/app/shared/services/account-management.service';
import { ApiResponse } from 'src/app/shared/types/api-response';
import { User } from 'src/app/shared/types/user';
import { Init } from 'v8';
import { AuthService } from 'src/app/auth/auth.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit, AfterViewInit {
  accounts: any = [];
  user: User | null;
  constructor(private modalService: NgbModal, private accountsManagement: AccountManagementService, private authService: AuthService, private changeDetection: ChangeDetectorRef, private messageService: MessageService) {

  }

  ngOnInit(): void {
    this.user = this.authService.currentUser;
  }

  openAddAccountModal() {
    const modalRef = this.modalService.open(AddEditAccountComponent,
      {
        size: 'md',
        centered: true,
        scrollable: true,
        keyboard: true
      });

      modalRef.result.then(() => {
        this.loadAccounts();
      });

  }

  openEditAccountModal(id: string) {
    const modalRef = this.modalService.open(AddEditAccountComponent,
      {
        size: 'md',
        centered: true,
        scrollable: true,
        keyboard: true
      });
    modalRef.componentInstance.id = id;

    modalRef.result.then(() => {
      this.loadAccounts();
    });

  }

  ngAfterViewInit(): void {
    this.loadAccounts();
  }

  loadAccounts() {
    this.accountsManagement.getAllAccounts().subscribe(data => {
      console.log(JSON.stringify(data));
      this.accounts = (data as ApiResponse).data;
      this.changeDetection.detectChanges();
    })
  }

  changeAccountStatus(id, enable) {
    if (enable) {
      this.accountsManagement.disableAccount(id).subscribe(data => {
        console.log(data)
      }, error => {
        this.messageService.add({severity:'error', summary:'Error updating account', detail: error?.error?.message}); }
      ); }
    else {
      this.accountsManagement.enableAccount(id).subscribe(data => {
        console.log(data)
      }, error => {
        this.messageService.add({severity:'error', summary:'Error updating account', detail: error?.error?.message}); }
      ); }
      this.loadAccounts();
  }

}
