<div class="modal-header">
  <div class="d-flex align-self-center w-100">
    <div class="flex-grow-1">
      <h3>Add a New User</h3>
    </div>
    <div class="pl-4 align-self-center"><button type="button" class="btn-close" aria-label="Close"
        (click)="close()"></button></div>
  </div>
</div>
<div class="modal-body mb-3">
  <div class="row">
    <div class="col">
      <div class="form-group">
        <label for="firstName">First Name <span class="astrix">*</span></label>
        <input id="firstName" type="text" class="form-control"  [ngClass]="{'is-invalid' : firstNameError}" [(ngModel)]="user.firstName">
      </div>
      <div class="form-group">
        <label for="lastName">Last Name <span class="astrix"></span></label>
        <input id="lastName" type="text" class="form-control" [(ngModel)]="user.lastName">
      </div>
      <div class="form-group">
        <label for="email">Email ID <span class="astrix">*</span></label>
        <input id="email" type="text" class="form-control" [ngClass]="{'is-invalid' : emailError}" [(ngModel)]="user.email">
      </div>
      <div class="form-group">
        <label for="role">Select Role <span class="astrix">*</span> <span class="icon-info ml-2"></span></label>
        <select class="custom-select form-control" id="role" [ngClass]="{'is-invalid' : roleError}" [(ngModel)]="user.role">
          <option *ngIf="environment.managementAccountId === accountId" value="accountmanager">Account Manager</option>
          <option value="admin" selected>Admin</option>
          <option value="sales">Sales</option>
          <option value="hiringmanager">Hiring Manager</option>
          <option value="recruiter">Recruiter</option>
        </select>
      </div>
    </div>
    <!--
      <div class="col-sm-8">
          <div class="role-detail-wrapper">
              <nav class="nav nav-underline">
                  <a class="nav-link active" href="javascript:void(0)">Admin</a>
                  <a class="nav-link" href="javascript:void(0)">Sales</a>
                  <a class="nav-link" href="javascript:void(0)">Hiring Manager</a>
                  <a class="nav-link" href="javascript:void(0)">Recruiter</a>
              </nav>
              <div class="role-description">
                  <div class="rd-list">
                      Market Search and Analytics
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In egestas lorem non nulla sagittis</p>
                  </div>
                  <div class="rd-list">
                      Recruitment Module
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In egestas lorem non nulla sagittis</p>
                  </div>
                  <div class="rd-list">
                      Forecasting
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In egestas lorem non nulla sagittis</p>
                  </div>
                  <div class="rd-list">
                      Users Management
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In egestas lorem non nulla sagittis</p>
                  </div>
              </div>
          </div>
      </div>
      -->
  </div>
</div>
<div class="modal-footer">
  <div>
    <button *ngIf="user?._id" class="btn btn-outline-primary mr-3" (click)="resetPassword()">Reset Password</button>
    <button class="btn btn-outline-primary mr-3" (click)="close()">Cancel</button>
    <button class="btn btn-primary" (click)="onSubmit()">Submit</button>
  </div>
  <div *ngIf="message">{{message}}</div>
</div>
