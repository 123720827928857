export const environment = {
  production: false,
  apiUrl: 'https://dev.api.talentedgeai.com',
  forecastingService: 'https://forecasting.devapi.talentedgeai.com',
  queryEngineUrl: 'https://qe.devapi.talentedgeai.com',
  // queryEngineUrl: 'http://localhost:3001',

  aiModelUrl: 'https://ai.devapi.talentedgeai.com',
  authUrl: 'https://devauth.talentedgeai.com',
  //authUrl: 'http://localhost:3002',
  interviewApiUrl: 'https://interv.devapi.talentedgeai.com',
  intervuAiRequisitionsURL: 'https://talentedge.qa.intervu.ai/user/requisitions',
  intervuAiInterviewsURL: 'https://talentedge.qa.intervu.ai/user/requisitions/invitations',
  candidateServiceUrl: 'https://dev-candidate.talentedgeai.com',
  managementAccountId: '6698dbad5ab865401f73a6db'
};
