import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MarketSearchService } from '../../services/market-search.service';
import { SharedService } from '../../services/shared.service';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from '../../types/user';
import { environment } from 'src/environments/environment';
import { interval } from 'rxjs';

import * as searchQueries from '../../../../assets/search-queries.json';
import { strict } from 'assert';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  showDiv: boolean = true;
  public recentActivityList: any = [];
  public marketSearch: any = "";
  public user: User | null;
  public initials: any = "";

  searchOptions = searchQueries;
  searchSuggestions: string[] = new Array<string>;
  //public applySearchFilter: boolean = true;

  constructor(private router: Router, public marketSearchService: MarketSearchService,
    public sharedService: SharedService, private messageService: MessageService, private authService: AuthService, private render:Renderer2) {
    // Listen to the router events and filter for NavigationEnd events.
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      // Check the URL path and determine if the div should be shown or not.
      this.showDiv = event.url !== '/d/market-search';
    });
    //this.searchOptions = searchQueries.map(item => {return item;});
  }
  ngOnInit() {
    //let user:any = localStorage.getItem("li");
    this.user = this.authService.currentUser;
    this.createInitials();
  }

  ngAfterViewInit(): void {
    // needed to make NgAutoComplete control, component scss and html style is not working
    const searchDropbox = document.getElementsByClassName("p-inputtext").item(0) as HTMLElement;
    if(searchDropbox) {
      searchDropbox.style.padding = '0.15rem';
      searchDropbox.style.border = '0px';
      searchDropbox.style.width = '100%';
    };
    const searchDropwrapper = document.getElementsByClassName("p-inputwrapper").item(1) as HTMLElement;
    if(searchDropwrapper) {
      searchDropwrapper.style.width = '100%';
    };
  }


  getRecentActivities() {
    let userId = "1"; //change when auth guard added
    this.marketSearchService.getRecentActivities(this.user?._id).subscribe(results => {
      this.recentActivityList = results;
      this.sharedService.updateRecentActivities(this.recentActivityList);

    })
    // this.recentActivitiesUpdated.emit(this.recentActivityList);

  }

  onSearchChange(event: any) {
    console.log(event.query);
    const query: string = (event.query as string).toLowerCase();
    this.searchSuggestions = this.searchOptions.data.filter(function (str) {
       return str.includes(query);
    });
    /*if(this.applySearchFilter && (event.target.value as string).length > 3) {
      this.applySearchFilter = false;
      const countdown = interval(1000).subscribe((interval) => {
        countdown.unsubscribe();
        this.applySearchFilter = true;
      });
    }*/
  }

  onSearch() {
    this.marketSearchService.searchMarket(this.marketSearch, this.user?._id).subscribe((results: any) => {
      if (results.aggregation.length > 0) {
        this.getRecentActivities();
        this.router.navigate(['/d/forecasting'], {
          queryParams: {
            query: JSON.stringify(results?.aggregation),
            search: this.marketSearch,
            soc6Code: results?.soc6Code,
            jobCategory: results?.jobCategory,
            state: JSON.stringify(results?.dataMemberValue),
            isHourlySalary: results?.isHourlySalary,
            isCompany: results?.isCompany,
            companies: results?.companies,
            allResData: JSON.stringify(results)
          },
          // queryParamsHandling: 'merge'
        });
      }
      else {
        this.showWarn();
      }

    });
  }
  showWarn() {
    this.messageService.add({ severity: 'warn', key: 'gtr', detail: 'Please refine the query and search again' });
  }
  logout() {
    localStorage.clear();
    const callbackUrl = encodeURIComponent(location.origin + '/auth/callback');
    document.location.assign(environment.authUrl + '/auth/logout');
  }
  resetPassword() {

  }
  createInitials(){
    if (this.user?.firstName && this.user?.lastName){
      this.initials= this.user.firstName[0]+this.user.lastName[0];
      this.initials = this.initials.toUpperCase();
    }
    else if (this.user?.firstName && !this.user?.lastName){
      this.initials= this.user.firstName[0];
      this.initials = this.initials.toUpperCase();
    }
    else if (!this.user?.firstName && this.user?.lastName){
      this.initials= this.user.lastName[0];
      this.initials = this.initials.toUpperCase();
    }
    else{
      this.initials = "TE";
    }
  }
}
