<header>
  <div class="container-fluid">
    <div class="row">
      <div class="col branding"><img src="assets/logo/Login-Logo.svg" alt="TalentEdgeAI" /></div>
      <div class="col global-search" *ngIf="showDiv">
        <div class="position-relative">
          <span class="ic-prepend icon-search"></span>
          <!--<input type="text" class="form-control form-control-lg" placeholder="Search" [(ngModel)]="marketSearch"
            (keyup.enter)="onSearch()" (input)="onSearchChange($event)">-->
          <p-autoComplete class="form-control form-control-lg" [(ngModel)]="marketSearch" [suggestions]="searchSuggestions"
            (completeMethod)="onSearchChange($event)" (keyup.enter)="onSearch()"/>
          <span class="ic-append icon-mice"></span></div>
      </div>
      <div class="col user-profile">
        <div class="d-flex flex-row-reverse align-items-center">
          <div class="pl-4">
            <!-- Dropdown Trigger -->
            <!-- <img src="assets/images/avatar-img.png" class="userpic dropdown-toggle" id="dropdownMenuLink"
              data-toggle="dropdown" aria-haspopup="true" alt="User"> -->

            <img src="https://via.placeholder.com/150/FCFCFC/102e42/?text={{initials}}" class="userpic dropdown-toggle"
              id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" alt="User">


            <!-- Dropdown Menu -->
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
              <span class="dropdown-item-text howdy">Hi, {{user?.firstName}} {{user?.lastName}}</span>
              <span class="dropdown-item-text howdy" style="font-size: x-small;">{{user?.role?.toUpperCase()}}</span>
              <span class="dropdown-item-text howdy"
                style="font-size: x-small;">{{user?.accountName?.toUpperCase()}}</span>
              <div class="dropdown-divider"></div>
              <!--<a class="dropdown-item btn btn-link btn-sm" (click)="resetPassword()">Reset Password</a>-->
              <a class="dropdown-item btn btn-link btn-sm" (click)="logout()">Logout</a>
            </div>
          </div>
          <div class="notification position-relative">
            <span class="icon-bell-ringing"></span>
            <!-- Notification Bubble based on count -->
            <!-- <div class="notification-bubble"></div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
