import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../types/api-response';
import { Account } from '../types/account';
import { User } from '../types/user';

@Injectable({
  providedIn: 'root'
})
export class AccountManagementService {
  authUrl = environment.authUrl;
  constructor(private http: HttpClient) { }

  getAllAccounts(filter?: string, pageNo: number = 1, pageSize: number = 15) {
    const response = this.http.get(`${this.authUrl}/accounts`);
    return response;
  }

  getAccountById(id: string) {
    const response = this.http.get(`${this.authUrl}/accounts/${id}`);
    return response;
  }

  addAccount(accountDetails: Account) {
    const response = this.http.post(`${this.authUrl}/accounts`, accountDetails);
    return response;
  }

  enableAccount(id: string) {
    const response = this.http.patch(`${this.authUrl}/accounts/${id}`, {isEnabled: true});
    return response;
  }

  disableAccount(id: string) {
    const response = this.http.patch(`${this.authUrl}/accounts/${id}`, {isEnabled: false});
    return response;
  }

  updateAccount(id: string, accountDetails: Account) {
    const response = this.http.patch(`${this.authUrl}/accounts/${id}`, accountDetails);
    return response;
  }

  getAllUsers(accountId: string) {
    const response = this.http.get(`${this.authUrl}/accounts/${accountId}/users`);
    return response;
  }

  getUserById(id: string) {
    const response = this.http.get(`${this.authUrl}/users/${id}`);
    return response;
  }

  addUser(accountId: string, userDetails: User) {
    const response = this.http.post(`${this.authUrl}/accounts/${accountId}/users`, userDetails);
    return response;
  }

  updateUser(id: string, userDetails: User) {
    const response = this.http.patch(`${this.authUrl}/users/${id}`, userDetails);
    return response;
  }

  requestChangeUserPassword(id: string) {
    const response = this.http.patch(`${this.authUrl}/users/${id}/changePassword`, {});
    return response;
  }

  resetUserPassword(id: string) {
    const response = this.http.patch(`${this.authUrl}/users/${id}/resetPassword`, {});
    return response;
  }

  enableUser(id: string) {
    const response = this.http.patch(`${this.authUrl}/users/${id}`, {isEnabled: true});
    return response;
  }

  disableUser(id: string) {
    const response = this.http.patch(`${this.authUrl}/users/${id}`, {isEnabled: false});
    return response;
  }

}
