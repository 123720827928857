export class Account {
  public constructor(init?: Partial<Account>) {
    Object.assign(this, init);
  }
  public name: string;
  public contactPerson: string;
  public email: string;
  public userLicenses: number;
  public hasMarketAnalysis: boolean;
  public hasRecruitment: boolean;
  public hasForecasting: boolean;
}
