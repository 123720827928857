import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountManagementRoutingModule } from './account-management-routing.module';
import { UsersComponent } from './users/users.component';
import { AccountsComponent } from './accounts/accounts.component';
import { AddEditAccountComponent } from './add-edit-account/add-edit-account.component';
import { AddEditUserComponent } from './add-edit-user/add-edit-user.component';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    UsersComponent,
    AccountsComponent,
    AddEditAccountComponent,
    AddEditUserComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    AccountManagementRoutingModule
  ]
})
export class AccountManagementModule { }
