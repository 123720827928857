<div class="login-wrapper">
  <header class="header">
    <img src="assets/logo/Login-Logo.svg" alt="TalentEdgeAI" class="logo" />
  </header>
  <div class="container">
    <div class="row">
      <div class="col-md-8 text-center">
        <img src="assets/Login-Graphic.svg" alt="login-page-Image" class="login-graphic">
      </div>
      <div class="col-md-4">
        <div class="login-container">
          <h3>Update Password</h3>
          <p>Password must be at least 8 characters long and contain a number or special
            character</p>
          <form (submit)="updatePassword()" #resetForm="ngForm" novalidate>
            <div class="rs-mb-5">
              <div class="position-relative">
                <input type="{{ passwordVisible ? 'text' : 'password' }}" [(ngModel)]="password"
                  class="form-control form-control-lg" id="newPassword" name="newPassword" placeholder="New Password *"
                  required #passwordInput="ngModel" minlength="8" pattern="^(?=.*[0-9!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$">
                  <button class="btn btn-link btn-sm btn-toggle" type="button"
                    (click)="togglePasswordVisibility()" title="Toggle password visibility">
                    {{ passwordVisible ? 'Hide' : 'Show' }}
                  </button>
              </div>
              <div *ngIf="passwordInput.invalid && passwordInput.touched" class="rs-error-list">
                <div *ngIf="passwordInput.errors && passwordInput.errors['required']">Password is required</div>
                <div *ngIf="passwordInput.errors && passwordInput.errors['minlength']">Password must be at least 8
                  characters long</div>
                <div *ngIf="passwordInput.errors && passwordInput.errors['pattern']">Password must contain a number or
                  special character</div>
              </div>
            </div>
            <div class="rs-mb-5">
              <div class="position-relative">
                <input type="{{ confirmPasswordVisible ? 'text' : 'password' }}" [(ngModel)]="confirmPassword"
                  class="form-control form-control-lg" id="confirmPassword" name="confirmPassword"
                  placeholder="Confirm Password *" required #confirmPasswordInput="ngModel" />
                  <button class="btn btn-link btn-sm btn-toggle" type="button"
                    (click)="toggleConfirmPasswordVisibility()" title="Toggle password visibility">
                    {{ confirmPasswordVisible ? 'Hide' : 'Show' }}
                  </button>
              </div>
              <div *ngIf="confirmPasswordInput.invalid && confirmPasswordInput.touched" class="rs-error-list">
                <div *ngIf="confirmPasswordInput.errors && confirmPasswordInput.errors['required']">Confirm Password is
                  required</div>
                <!-- <div *ngIf="confirmPasswordInput.errors && confirmPasswordInput.errors['minlength']">Confirm Password must be at least 8 characters long</div>
              <div *ngIf="confirmPasswordInput.errors && confirmPasswordInput.errors['pattern']">Confirm Password must contain a number or special character</div> -->
              </div>
            </div>
            <div *ngIf="passwordError" class="alert alert-danger" role="alert">
              {{passwordErrorMessage}}
            </div>
            <div *ngIf="passwordSuccess" class="alert alert-success" role="alert">
              Password Reset Successfully
            </div>
            <button type="submit" class="btn btn-primary w-100 btn-lg">Continue</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="footer">© 2024 TalentEdgeAI All rights reserved. | <a href="#">Policy</a> | <a href="#">Terms of Service</a>
</div>