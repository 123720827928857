import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  constructor(private readonly activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    const callbackUrl = encodeURIComponent(location.origin + '/auth/callback');
    const redirectUrl = this.activatedRoute.snapshot.queryParamMap.get('redirect_url');
    let loginUrl = environment.authUrl + '/auth' + '?callback_url=' + callbackUrl;
    if(redirectUrl) loginUrl += '&redirect_url=' + redirectUrl;
    console.log('Redirect for login: ' + loginUrl);
    document.location.assign(loginUrl);
  }
}
