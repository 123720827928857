import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { User } from 'src/app/shared/types/user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private jwthelper = new JwtHelperService();
  constructor(private http: HttpClient, private router: Router) { }
  apiUrl = environment.apiUrl;

  login(email: string, password: string): Observable<any> {
    console.log("login apiUrl = ", this.apiUrl);
    // Make a HTTP request to your backend API to authenticate the user
    // For example:
    //http://3.134.199.42:3000/auth/login
    return this.http.post(`${this.apiUrl}/auth/login`, { email, password });
  }

  forgetPassword(email: string): Observable<any> {
    // Make a HTTP request to your backend API to authenticate the user
    // For example:
    //http://3.134.199.42:3000/auth/login
    return this.http.post(`${this.apiUrl}/auth/forgetPassword`, { email });
  }
  resetPassword(password: string, confirmPassword: string, token: string): Observable<any> {
    // Make a HTTP request to your backend API to authenticate the user
    // For example:
    //http://3.134.199.42:3000/auth/login
    return this.http.post(`${this.apiUrl}/auth/resetPassword`, { password, confirmPassword, token });
  }
  logout(redirectUrl?: string) {
    localStorage.removeItem('at');
    this.router.navigate(['/auth/login'], {queryParams: {'redirect_url': redirectUrl}});
  }

  public set token(v : string) {
    localStorage.setItem('at', v);
  }

  public get token(): string | null {
    return localStorage.getItem('at');
  }

  public get isLoggedIn(): boolean {
    const token = localStorage.getItem('at');
    if (!token) {
      return false;
    }
    const isExpired = this.jwthelper.isTokenExpired(token);
    return !isExpired;
  }

  get currentUser(): User | null {
    const token = localStorage.getItem('at');
    if (!token) {
      return null;
    }
    const payload = this.jwthelper.decodeToken(token);
    return new User(payload?payload:null);
  }
}
